export default {
    "221": true,
    "222": true,
    "223": true,
    "224": true,
    "225": true,
    "226": true,
    "227": true,
    "228": true,
    "229": true,
    "231": true,
    "232": true,
    "233": true,
    "235": true,
    "236": true,
    "237": true,
    "238": true,
    "241": true,
    "243": true,
    "244": true,
    "245": true,
    "246": true,
    "247": true,
    "248": true,
    "249": true,
    "271": true,
    "272": true,
    "273": true,
    "274": true,
    "275": true,
    "276": true,
    "278": true,
    "279": true,
    "281": true,
    "282": true,
    "283": true,
    "284": true,
    "285": true,
    "287": true,
    "288": true,
    "294": true,
    "296": true,
    "297": true,
    "311": true,
    "312": true,
    "313": true,
    "314": true,
    "315": true,
    "316": true,
    "317": true,
    "319": true,
    "321": true,
    "322": true,
    "323": true,
    "324": true,
    "325": true,
    "326": true,
    "327": true,
    "328": true,
    "329": true,
    "33": true,
    "341": true,
    "342": true,
    "343": true,
    "344": true,
    "345": true,
    "346": true,
    "347": true,
    "348": true,
    "349": true,
    "351": true,
    "352": true,
    "353": true,
    "354": true,
    "355": true,
    "356": true,
    "357": true,
    "358": true,
    "359": true,
    "371": true,
    "372": true,
    "373": true,
    "374": true,
    "375": true,
    "376": true,
    "377": true,
    "378": true,
    "381": true,
    "382": true,
    "383": true,
    "384": true,
    "385": true,
    "386": true,
    "387": true,
    "388": true,
    "389": true,
    "391": true,
    "392": true,
    "393": true,
    "394": true,
    "395": true,
    "411": true,
    "412": true,
    "413": true,
    "414": true,
    "415": true,
    "417": true,
    "418": true,
    "419": true,
    "421": true,
    "422": true,
    "423": true,
    "424": true,
    "425": true,
    "426": true,
    "427": true,
    "428": true,
    "429": true,
    "431": true,
    "432": true,
    "433": true,
    "434": true,
    "435": true,
    "436": true,
    "437": true,
    "438": true,
    "441": true,
    "442": true,
    "443": true,
    "444": true,
    "445": true,
    "447": true,
    "448": true,
    "449": true,
    "451": true,
    "452": true,
    "453": true,
    "454": true,
    "455": true,
    "456": true,
    "457": true,
    "458": true,
    "459": true,
    "461": true,
    "462": true,
    "463": true,
    "464": true,
    "465": true,
    "466": true,
    "467": true,
    "468": true,
    "469": true,
    "471": true,
    "472": true,
    "473": true,
    "474": true,
    "475": true,
    "476": true,
    "477": true,
    "478": true,
    "481": true,
    "482": true,
    "483": true,
    "485": true,
    "486": true,
    "487": true,
    "488": true,
    "489": true,
    "492": true,
    "493": true,
    "494": true,
    "495": true,
    "496": true,
    "498": true,
    "499": true,
    "55": true,
    "56": true,
    "561": true,
    "563": true,
    "588": true,
    "591": true,
    "592": true,
    "593": true,
    "594": true,
    "595": true,
    "596": true,
    "597": true,
    "599": true,
    "612": true,
    "613": true,
    "614": true,
    "615": true,
    "616": true,
    "618": true,
    "621": true,
    "622": true,
    "623": true,
    "624": true,
    "625": true,
    "626": true,
    "627": true,
    "628": true,
    "629": true,
    "631": true,
    "632": true,
    "633": true,
    "634": true,
    "635": true,
    "636": true,
    "637": true,
    "638": true,
    "639": true,
    "641": true,
    "642": true,
    "643": true,
    "644": true,
    "645": true,
    "646": true,
    "647": true,
    "648": true,
    "649": true,
    "651": true,
    "652": true,
    "653": true,
    "656": true,
    "657": true,
    "658": true,
    "659": true,
    "661": true,
    "662": true,
    "664": true,
    "665": true,
    "667": true,
    "668": true,
    "669": true,
    "671": true,
    "672": true,
    "673": true,
    "674": true,
    "675": true,
    "676": true,
    "677": true,
    "686": true,
    "687": true,
    "694": true,
    "695": true,
    "696": true,
    "697": true,
    "698": true,
    "711": true,
    "712": true,
    "713": true,
    "714": true,
    "715": true,
    "716": true,
    "717": true,
    "718": true,
    "719": true,
    "721": true,
    "722": true,
    "723": true,
    "724": true,
    "725": true,
    "726": true,
    "727": true,
    "728": true,
    "731": true,
    "732": true,
    "733": true,
    "734": true,
    "735": true,
    "736": true,
    "737": true,
    "738": true,
    "739": true,
    "741": true,
    "742": true,
    "743": true,
    "744": true,
    "745": true,
    "746": true,
    "747": true,
    "748": true,
    "749": true,
    "751": true,
    "753": true,
    "754": true,
    "755": true,
    "756": true,
    "757": true,
    "758": true,
    "759": true,
    "761": true,
    "762": true,
    "763": true,
    "764": true,
    "765": true,
    "766": true,
    "767": true,
    "768": true,
    "769": true,
    "771": true,
    "772": true,
    "773": true,
    "774": true,
    "775": true,
    "776": true,
    "777": true,
    "778": true,
    "779": true,
    "781": true,
    "782": true,
    "783": true,
    "784": true,
    "785": true,
    "786": true,
    "789": true,
    "791": true,
    "797": true,
    "81": true,
    "821": true,
    "823": true,
    "824": true,
    "825": true,
    "826": true,
    "828": true,
    "829": true,
    "831": true,
    "832": true,
    "833": true,
    "834": true,
    "835": true,
    "836": true,
    "841": true,
    "842": true,
    "844": true,
    "845": true,
    "846": true,
    "861": true,
    "862": true,
    "864": true,
    "866": true,
    "867": true,
    "868": true,
    "869": true,
    "871": true,
    "872": true,
    "873": true,
    "877": true,
    "878": true,
    "891": true,
    "892": true,
    "894": true,
    "897": true,
    "899": true,
    "913": true,
    "914": true,
    "916": true,
    "917": true,
    "918": true,
    "919": true,
    "921": true,
    "922": true,
    "923": true,
    "924": true,
    "932": true,
    "933": true,
    "934": true,
    "936": true,
    "937": true,
    "938": true,
    "951": true,
    "953": true,
    "954": true,
    "958": true,
    "961": true,
    "962": true,
    "963": true,
    "964": true,
    "965": true,
    "966": true,
    "967": true,
    "968": true,
    "969": true,
    "971": true,
    "972": true,
    "981": true,
    "982": true,
    "983": true,
    "984": true,
    "985": true,
    "986": true,
    "987": true,
    "988": true,
    "991": true,
    "992": true,
    "993": true,
    "994": true,
    "995": true,
    "996": true,
    "997": true,
    "998": true,
    "999": true
}